import { Box, Button, Grid, TextField, Typography, Dialog, DialogContent, Avatar} from '@mui/material';
import axios from 'axios';
import { default as React, useEffect, useState } from 'react';
import { setUserSession, getToken } from './Utils/Common';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
const customHeaders = {
  'Content-type': 'application/json',
};


function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const newPassword = useFormInput('');
  const passwordCheck = useFormInput('');
  const [error, setError] = useState(null);
  const [passError, setPassError] = useState(null);
  const [userID, setUserID] = useState(null);
  const [passReset, setPassReset] = useState(null);
  const [openReset, setOpenReset] = useState(false);
  
  const token = getToken();

  const config = {
    headers: { Authorization: `Bearer ${token}` }
   };

  //handleClick of the ChangePassword Form
  const handleChangePass = (evt) => {
    evt.preventDefault();
    setError(null);
    console.log(password, passwordCheck)
    if(password.value != null && password.value === passwordCheck.value){
      console.log('in handlePassReset');
      setLoading(true);
      console.log(userID);
      if(userID!=null){
        axios.put(`/api/v1/user/${userID}`, {password: password.value }, config).then(response => {
          console.log('in response:', response);
          setLoading(false);
          props.history.push('/Home');
          window.location.reload();
        }).catch(error => {
          console.log(error);
          setLoading(false);
          if (error.response.status === 401) {setError(error.response.data.message);}
          else {setError("Something went wrong. Please try again later.");}
        });
      }
    }
    else{
      setError("Passwords fields must be non-empty and match.");
    }
}
  const handleGetPassID = (evt) => {
    evt.preventDefault();
    setPassError(null);
    console.log('in handlePassReset');
    if(username == null){
      setError("You must enter a email address to request new valid password.");
      
    }
    else{
    setLoading(true);
    console.log(username.value);
    axios.get(`/api/v1/user/${username.value}/reset`, customHeaders).then(response => {
      console.log('in response:', response);
      setLoading(false);
      setError("A temporary password has been sent to your email.");
      setUserID(response.data.user_id);
    }).catch(error => {
        console.log('error');
        setLoading(false);
        if (error.response.status === 401) setError(error.response.data.message);
        else if(error.response.status == 404){
          setError("You must enter a valid email address to request new valid password.");
        }
        else setError("Something went wrong. Please try again later.");
      });
    }
  }

  // handle button click of login form
  const handleLogin = (evt) => {
    evt.preventDefault();
    console.log('in handleLogin');
    setError(null);
    setLoading(true);
    if(!username.value || !password.value) {
      setError("All fields are required.");
      setLoading(false);
    }
    else {
      axios.post('/api/login', { username: username.value, password: password.value }, customHeaders).then(response => {
        console.log('in response:');
        console.log(response);
        setLoading(false);
        //NOTE: response.data.role refers to a privilege level in the response data
        //NOTE: response.data.privilege reffers to a role var in the response data
        if (response.data.message && response.data.message.includes("doesn't exist")) {
          setError("User does not exist.");
        }
        else {
          if (response.data.temporary_login === "True") {
            setPassReset(false);
            setOpenReset(true);
            setUserID(response.data.user_id);
            console.log("flag true");
            if (response.data.token) {
              setUserSession(response.data.token, response.data.user, response.data.privilege, response.data.role, response.data.fname, response.data.lname, response.data.studies);
            }
          }
          else {
            setUserSession(response.data.token, response.data.user, response.data.privilege, response.data.role, response.data.fname, response.data.lname, response.data.studies);
            setUserID(response.data.user_id);
            props.history.push('/Home');
            window.location.reload();
          }
        }
      }).catch(error => {
        console.log('error');
        setLoading(false);
        if (error.response.status === 401) {setError(error.response.data.message);}
        else if (error.response.status === 403) {
          if(error.response.data.message && error.response.data.message.includes("Wrong credentials")) {
            setError("Incorrect credentials, check your email and password and try again");
          }
          else if(error.response.data.message && error.response.data.message.includes("Login Error")) {
            setError("Incorrect credentials, check your email and password and try again");
          }
          else {
            setError("Something went wrong. Please try again later.");
          }
        }
        else if (error.response.status === 400) {
          if (error.response.data.message.username && error.response.data.message.includes("email address of user")) {
            setError("User does not exist.")
          }
          else {
            setError("Something went wrong. Please try again later.");
          }
        }
        else if (error.response.status === 404) {
          if(error.response.data.message && error.response.data.message.includes("Login Error")) {
            setError("Incorrect credentials, check your email and password and try again");
          }
          else {
            setError("Something went wrong. Please try again later.");
          }
        }
        else if (error.response.data.temporary_login === "True"){
          setPassReset(false);
          setOpenReset(true);
        }
        else setError("Something went wrong. Please try again later.");
      });
    }
  }

  return (
    <Dialog open={true} BackdropProps={{
    style: {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  }}>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img
                src={
                  process.env.PUBLIC_URL + "/ctx_logo.png"
                }
                style={{ height: "50px" }}
                alt=""
            />
          </Grid>
            
          <Grid item>
          {openReset?<Typography component="h1" variant="h5" align="center">
              Set New GSConnect Password
            </Typography>:<div>

            {passReset?<Typography component="h1" variant="h5" align="center">
              GSConnect Password Reset
            </Typography>:<Typography component="h1" variant="h5" align="center">
              GSConnect Login
            </Typography>}</div>}
          </Grid>
          <Grid item align="center">
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <form noValidate>
              {openReset?<TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled
                id="username"
                label="Email Address"
                name="username"
                autoComplete="email"
                autoFocus
                {...username}
              />:<TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
              {...username}
            />}
             {openReset?
             <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                autoComplete="off"
                {...newPassword}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="Re-Enter password"
                label="Re-Enter Password"
                type="password"
                id="password"
                autoComplete="off"
                {...passwordCheck}
              />
              </div>:<div>{passReset?<></>:<TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                {...password}
              />}</div>}
              {openReset?<Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e)=> handleChangePass(e)} disabled={loading}
            >
                Login
              </Button>:<div>
              {passReset?<Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e)=> handleGetPassID(e)} disabled={loading}
              >Change Password
              </Button>:<Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e)=> handleLogin(e)} disabled={loading}
            >
                Login
              </Button>}
              </div>}
              <Box mt={1}>
                <Typography sx={{ color: 'red' }}>{error || <>&nbsp;</>}</Typography>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                >
                  Don't have an account? Sign Up
                </Button>
              </Box>
              <Box>
              {passReset
                ?
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  onClick={()=>{
                    setPassReset(false);
                    setError(null);
                  }}
                  disabled={loading}
                >
                  Return to Login
                </Button>
                :<></>
                }
                {passReset
                ?
                <></>
                :
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  onClick={()=>{
                    setPassReset(true);
                    setError(null);
                  }}
                  disabled={loading}
                >
                  Forgot Password?
                </Button>}
              </Box>
            </form>
          </Grid>
      </Grid>
      </DialogContent>
    </Dialog>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;
